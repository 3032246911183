<template>
    <my-layout>
        <template #body>
            <div class="home_main">
                <div class="header" id="header">
                    <img src="../../assets/img/h5/logo_h5.png"/>
                    <div class="right">
                        <span @click="$router.push('/aboutUs_h5')">关于</span>
                        <div class="button" @click="handleLogin">登录/注册</div>
                    </div>
                </div>
                <div class="banner">
                    <van-button plain type="info" @click="handleLogin">立即体验</van-button>
                    <div class="data">
                        <div class="item">
                            <countTo class="count" :startVal='0' :endVal='parseInt(monitorData.record_cnt) || 0' :duration='1500' autoplay></countTo>
                            <span class="text">创意素材</span>
                        </div>
                        <div class="item">
                            <countTo class="count" :startVal='0' :endVal='parseInt(monitorData.word_cnt) || 0' :duration='1500' autoplay></countTo>
                            <span class="text">关键词</span>
                        </div>
                        <div class="item">
                            <countTo class="count" :startVal='0' :endVal='parseInt(monitorData.domain_cnt) || 0' :duration='1500' autoplay></countTo>
                            <span class="text">推广域名</span>
                        </div>
                        <div class="item">
                            <countTo class="count" :startVal='0' :endVal='parseInt(monitorData.company_cnt) || 0' :duration='1500' autoplay></countTo>
                            <span class="text">推广公司</span>
                        </div>
                    </div>
                </div>
                <div class="challenge"></div>
                <div class="ai"></div>
                <div class="advantage"></div>
                <div class="slideshow">
                    <div class="title">客户评价</div>
                    <van-swipe class="my-swipe" :autoplay="2000" indicator-color="#1f81f8">
                        <van-swipe-item>
                            <div class="swipe1"></div>
                        </van-swipe-item>
                        <van-swipe-item>
                            <div class="swipe2"></div>
                        </van-swipe-item>
                        <van-swipe-item>
                            <div class="swipe3"></div>
                        </van-swipe-item>
                    </van-swipe>
                </div>
                <div class="bottom" id="apply_h5">
                    <div class="title">免费试用名额限时领取中</div>
                    <div class="btn" @click="handleLogin">立即抢占名额</div>
                    <img class="server" src="../../assets/img/h5/server_group.png"/>
                </div>
                <div class="bottom_right">
                    <div class="text">Copyright© 2021-2024 上海凭安网络科技有限公司 AI竞投 aijingtou.com All Rights Reserved</div>
                    <div class="text" @click="toLink">沪ICP备12039960号-38</div>
                </div>
            </div>
        </template>
        <template #footer>
            <div class="bottom_fix">
                <a class="left" href="tel:4006-173-173" @click="handleFire">
                    <img src="../../assets/img/h5/b_left.png"/>
                    <span>电话咨询</span>
                </a>
                <span class="right"  @click="handleLogin">
                    <img src="../../assets/img/h5/b_rightr.png"/>
                    <span>立即体验</span>
                </span>
            </div>
        </template>
    </my-layout>
</template>

<script>
  import * as api from "@/api/home"
  import { Button, Swipe, SwipeItem, Form, Field, Toast  } from 'vant';
  import { get_user_info } from '@/api/common'
  import PinganAna from 'pingansec-vue-ana'
  import * as apiDataView from "@/api/dataView"
  import countTo from 'vue-count-to';
  export default {
    name: "home",
    components: {
      [Button.name]:Button,
      [Swipe.name]:Swipe,
      [SwipeItem.name]:SwipeItem,
      [Form.name]:Form,
      [Field.name]:Field,
      [Toast .name]:Toast,
      countTo
    },
    data() {
      return {
        infoForm: {
          company_name: '',
          user_name: '',
          phone: '',
          source: 'h5',
        },
        disable: false,
        monitorData: {},
      }
    },
    created() {
      PinganAna.fire(3029)
      if(this.$route.query.fdhgjksdh) {
        localStorage.setItem('ajt_source', this.$route.query.fdhgjksdh)
      }
      this.getDefaultCnt()
    },
    mounted() {
      if(this.$route.query.scroll) {
        this.$nextTick(() =>{
          this.jump('apply_h5', false)
        })
      }
    },
    methods: {
      toLink() {
        window.open('https://beian.miit.gov.cn/#/Integrated/index','_blank')
      },
      handleFire() {
        PinganAna.fire(3034)
      },
      handleLogin() {
        PinganAna.fire(3036)
        get_user_info().then(res => {
          if(res.data.result_code == 0){
            this.$router.push('/box/dataView');
          }
        })
      },
      jump(id, fire = true) {
        var element = document.getElementById(id);
        element.scrollIntoView({behavior: "smooth"});
        if(fire && id === 'apply_h5') {
          PinganAna.fire(3031)
        }
      },
      getDefaultCnt() {
        let p = new Promise((resolve, reject) => {
          apiDataView.ad_statistics().then(res => {
            if(res.data.result_code == 0) {

              resolve(res.data.data);
              this.monitorData = res.data.data
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
        })
        return p;
      },
      submitInfo() {
        if(this.infoForm.company_name == '' || this.infoForm.phone == '') {
          Toast('公司名和手机不能为空！');
          return;
        }
        api.add_message(this.infoForm).then(res => {
          if(res.data.result_code == 0) {
            Toast('发送成功，我们会尽快联系您');
            this.disable = true;
            PinganAna.fire(3033)
          } else {
            Toast(res.data.message);
          }
        })
      }
    }
  }
</script>

<style scope lang="scss">
.home_main {
    width: 100%;
    overflow: scroll;
    background-color: #fff;
    .header {
        width: 100%;
        height: 170px;
        background-color: #ffffff;
        color: #1f81f8;
        font-size: 42px;
        display: flex;
        align-items: center;
        position: relative;
        img  {
            width: 377px;
            height: 111px;
            position: absolute;
            left: 58px;
        }
        .right {
            position: absolute;
            right: 58px;
            .button {
                width: 240px;
                height: 90px;
                background-color: #ffffff;
                border: solid 3px #1f81f8;
                border-radius: 50px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin-left: 76px;
                font-size: 36px;
                &:active {
                    background-color: #f6f9fe;
                }
            }
        }

    }
    .banner {
        width: 100%;
        height: 1325px;
        background: url("../../assets/img/h5/h5_banner.png") center center;
        background-size: cover;
        position: relative;
        .van-button {
            width: 346px;
            height: 110px;
            background-color: #28a745;
            border-radius: 7px;
            position: absolute;
            left: 60px;
            top: 416px;
            color: #fff;
        }
        .data {
            width: 1000px;
            height: 419px;
            position: absolute;
            left: calc(50% - 500px);
            bottom: -210px;
            background-color: #ffffff;
            background: url("../../assets/img/h5/data.png") center center;
            background-size: cover;
            box-shadow: 3px 4px 30px 0px
            rgba(153, 153, 153, 0.2);
            border-radius: 20px;
            display: flex;
            flex-wrap: wrap;
            .item {
                display: inline-flex;
                flex-direction: column;
                min-width: 455px;
                position: relative;
                top: 57px;
                left: 47px;
                &:nth-child(2n) {
                    left: 94px;
                }
                .count {
                    font-size: 48px;
                    color: #1f81f8;
                    font-weight: bold;
                }
                .text {
                    font-size: 42px;
                    color: #333333;
                    margin-top: 24px;
                }
            }
        }
    }
    .challenge {
        width: 100%;
        height: 1309px;
        background: url('../../assets/img/h5/challenge.png');
        background-size: cover;
        margin-top: 200px;
    }
    .ai {
        width: 100%;
        height: 2635px;
        background: url('../../assets/img/h5/ai_h5.png');
        background-size: cover;
    }
    .advantage {
        width: 961px;
        height: 749px;
        background: url('../../assets/img/h5/advantage.png');
        background-size: cover;
        margin: 100px auto;
    }
    .slideshow {
        width: calc(100% - 120px);
        height: 1092px;
        background: url('../../assets/img/h5/slide_bg.png');
        background-size: cover;
        padding-top: 135px;
        padding-bottom: 103px;
        padding-left: 60px;
        padding-right: 60px;
        .title {
            text-align: center;
            font-size: 66px;
            color: #333333;
            margin-bottom: 88px;
        }
        .my-swipe .van-swipe-item {
            width: 80%;
            height: 860px;
            background-color: #ffffff;
            border-radius: 12px;
        }
        .swipe1 {
            width: 960px;
            height: 860px;
            background: url("../../assets/img/h5/swipe1.png");
            background-size: cover;
        }
        .swipe2 {
            width: 960px;
            height: 860px;
            background: url("../../assets/img/h5/swipe2.png");
            background-size: cover;
        }
        .swipe3 {
            width: 960px;
            height: 860px;
            background: url("../../assets/img/h5/swipe3.png");
            background-size: cover;
        }
    }
    .bottom {
        background-color: #1f81f8;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #ffffff;
        padding: 60px 0;
        .title {
            margin-top: 76px;
            font-size: 66px;
        }
        .btn {
            width: 610px;
            height: 130px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            border-radius: 80px;
            color: #1f81f8;
            font-size: 48px;
            margin-top: 62px;
        }
        .server {
            width: 816px;
            height: 225px;
            margin-top: 73px;
            margin-bottom: 71px;
        }
    }
    .bottom_right {
        padding: 67px 0;
        text-align: center;
        font-size: 36px;
        line-height: 60px;
        color: #999999;
    }
}
.layout_footer {
    .bottom_fix {
        width: 100%;
        height: 150px;
        display: inline-flex;
        font-size: 42px;
        .left {
            width: 50%;
            height: 150px;
            background-color: #FFFFFF;
            color: #1f81f8;
            display: flex;
            align-items: center;
            border-top: 1px solid #e4e4e4;
            box-sizing: border-box;
        }
        .right {
            width: 50%;
            height: 150px;
            background-color: #1e81f8;
            color: #FFFFFF;
            display: flex;
            align-items: center;
        }
        img {
            width: 54px;
            height: 54px;
            margin-left: 134px;
            margin-right: 39px;
        }
    }
}


</style>
