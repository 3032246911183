import http from '@/utils/http'


// 提交申请信息
export function add_message(data) {
  return http({
    url: '/cc/ac-add_message',
    transformRequest: [(data) => {
      return data
    }],
    header: {
      'Content-Type': 'application/json'
    },
    method: 'post',
    data: JSON.stringify(data)
  })
}
